
.Counter {

    height:20px;
    width: 20px;
    line-height: 20px;
    xpadding-left: 6px;
    xpadding-right: 6px;
    font-size: smaller;
    margin:6px;
    border-radius: 10px;
    color:#ffffff;
    background-color: #bbbbbb;
    align-items: center;
}